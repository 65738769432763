<template>
  <div v-loading="loading">
    <h2 class="title">权限配置</h2>
    <div v-if="config">
      <el-form label-width="220px" label-position="left">
        <template v-for="(item, index) in config">
          <el-form-item :key="item.key" :label="item.label" v-show="item.is_show">
                      <!-- <el-checkbox  v-model="config[index].visitor_auth" :disabled="item.visitor_auth === -1" :true-label="1" :false-label="0"
              @change="updateRelation(index, 0)">未登录可{{ item.type === 'read' ? '看' : '用' }}</el-checkbox>
            <el-checkbox v-if="item.user_auth === -1" :disabled="true" :true-label="1" :false-label="0">非会员可{{ item.type
              === 'read' ? '看' : '用' }}</el-checkbox>
            <el-checkbox v-else v-model="config[index].user_auth" :true-label="1" :false-label="0"
              @change="updateRelation(index, 1)">登录注册可{{ item.type === 'read' ? '看' : '用' }}</el-checkbox>
            <el-checkbox v-if="item.associate_auth === -1" :disabled="true" :true-label="1" :false-label="0">会员可{{
              item.type
              === 'read' ? '看' : '用' }}</el-checkbox>
            <el-checkbox v-else v-model="config[index].associate_auth" :true-label="1" :false-label="0"
              @change="updateRelation(index, 2)">互联协会互联会员登录可{{ item.type === 'read' ? '看' : '用' }}</el-checkbox> -->
            <!-- <template v-if="item.paid_auth !== -2">
              <el-checkbox v-if="item.paid_auth === -1" :disabled="true" :true-label="1" :false-label="0">会员可缴费{{
                item.type
                === 'read' ? '看' : '用' }}</el-checkbox>
              <el-checkbox v-else v-model="config[index].paid_auth" :true-label="1" :false-label="0"
                @change="updateRelation(index, 3)">会员缴费可{{ item.type === 'read' ? '看' : '用' }}</el-checkbox>
            </template> -->
            <template v-for="(item2,index2) in auth" >
             <template v-if="item[item2]!== -2">
              <el-checkbox v-model="config[index][item2]" :disabled="item[item2]=== -1" :true-label="1" :false-label="0" :key="index2"
                @change="updateRelation(index, index2)">{{text[index2]}}{{ item.type === 'read' ? '看' : '用' }}</el-checkbox>
            </template>
          </template>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <fixed-action-bar>
      <el-button type="primary" :loading="saveLoading" :disabled="!config" @click="saveMySettings">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import { getSettings, setSettings } from "@/modules/non-cyc-user/api/settings";
import FixedActionBar from "@/base/layout/FixedActionBar";
import TemplateCard from '@/modules/big-data/components/TemplateCard.vue';

export default {
  name: "settings",
  components: { FixedActionBar },
  data() {
    return {
      loading: true,
      saveLoading: false,
      config: null,
      // 变量名 （权限从低到高）
      auth: ['visitor_auth', 'associate_auth','user_auth','prepare_auth','formal_auth',  'paid_auth',],
      text:['未登录可', '互联协会互联会员登录可','登录可','预备会员可','正式会员可','会员缴费可']
    }
  },
  created() {
    this.getMySettings();
  },
  methods: {
    updateRelation(i, bol) {
      // if (!bol) {
      //   /**
      //    * 设置的是"游客可用"的权限，
      //    * 增加游客的权限，也要增加非会员的权限
      //    */

      //   if (this.config[i].visitor_auth) {
      //     if (this.config[i].user_auth !== -1) this.config[i].user_auth = 1;
      //   }
      // } else {
      //   /**
      //    * 设置的是"非会员用户可用"的权限，
      //    * 取消非会员的权限，也要取消游客的权限
      //    */
      //   if (this.config[i].user_auth === 0) {
      //     if (this.config[i].visitor_auth !== -1) this.config[i].visitor_auth = 0;
      //   }
      // }
      // switch (bol) {
      //   case 0:
      //     // 点击游客可用时，非会员和互联协会会员也为可用
      //     if (this.config[i].visitor_auth === 1) {
      //       if (this.config[i].user_auth !== -1) this.config[i].user_auth = 1;
      //       if (this.config[i].associate_auth !== -1) this.config[i].associate_auth = 1;
      //     } 
      //     break;
      //   case 1:
      //       // 点击非会员可用时，互联协会会员也为可用
      //     if (this.config[i].user_auth === 1) {
      //       if (this.config[i].associate_auth !== -1) this.config[i].associate_auth = 1;
      //     }     //点击非会员不可用时。游客也为不可以
      //     if (this.config[i].user_auth === 0) {
      //       if (this.config[i].visitor_auth !== -1) this.config[i].visitor_auth = 0;
      //     }
      //     break;
      //   case 2:
      //      //点击互联协会会员不可用时， 游客和非会员也不可以
      //      if (this.config[i].associate_auth === 0) {
      //       if (this.config[i].visitor_auth !== -1) this.config[i].visitor_auth = 0;
      //       if (this.config[i].user_auth !== -1) this.config[i].user_auth = 0;
      //     }
      //   case 3:
      //     //点击互联协会会员不可用时， 游客和非会员也不可以
      //     if (this.config[i].associate_auth === 0) {
      //       if (this.config[i].visitor_auth !== -1) this.config[i].visitor_auth = 0;
      //       if (this.config[i].user_auth !== -1) this.config[i].user_auth = 0;
      //     }
      //   default:
      // }
      // bol 当前等级, index 每个的等级 
      this.auth.forEach((item, index) => {
        // -1为禁止修改 ，-2为不显示
        if (this.config[i][item]>= 0) {
          // 高级关时低级关 
          if (index < bol&&this.config[i][this.auth[bol]] === 0) {
            this.config[i][item] = 0
          // 低级开时高级开 
          } else if (index > bol&&this.config[i][this.auth[bol]] === 1) {
            this.config[i][item] = 1
          }
        }
      })
    },
    getMySettings() {
      getSettings().then(res => {
        this.config = res.data;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    saveMySettings() {
      this.saveLoading = true;
      setSettings(this.config).then(res => {
        this.$message.success(res.msg);
        this.saveLoading = false;
      }).catch(err => {
        this.saveLoading = false;
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}

.el-checkbox:nth-child(1),
.el-checkbox:nth-child(2) {
  min-width: 160px;
}

</style>
